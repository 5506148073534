import {OverlayOptions} from "./Overlay.js";

const tools = require('./tools.cjs');

class Handler {
    constructor(anchor) {
        this.anchor = anchor;
    }

    handleClick(e) {
        throw 'Implement this in a subclass';
    }
}

class SimpleLink extends Handler{
  handleClick(e) {
    window.location.href = this.anchor.dataset.target;
  }
}

class DomEltTarget extends Handler {
    constructor(anchor) {
        super(anchor);
        if (anchor.dataset.target) {
            this.target = document.querySelector(anchor.dataset.target);
        }
    }
}

class OverlayHandler extends DomEltTarget {

    constructor(anchor) {
        super(anchor);
    }

    handleClick(e) {
        if (this.target) {
            new OverlayOptions()
                .setElement(this.target)
                .display()
        }
    }
}

class OpenFeedHandler extends DomEltTarget {
    constructor(anchor) {
        super(anchor);
        this.target.querySelector('.close-btn').addEventListener('click', () => {
            this.target.classList.remove('visible');
            anchor.style.display = 'block';
        })
    }

    handleClick(e) {
        if (this.target) {
            this.target.classList.add('visible')
            this.anchor.style.display = 'none'
        }
    }
}

class ScrollToHandler extends DomEltTarget {
    constructor(anchor) {
        super(anchor);
    }

    handleClick(e) {
        if (this.target) {
            let margin = (this.anchor.dataset.margin || 0) * 1;
            if (margin === 1) {
                const contact = document.querySelector('#kontakt')
                if (contact.getBoundingClientRect().height > window.innerHeight) {
                    margin = 120
                    if (window.innerWidth > 868) {
                        margin = margin * 2
                    }
                }
            } else {
                if (window.innerWidth > 868) {
                    margin = margin * 2
                }
            }
            tools.scrollToElt(window, this.target, margin);
            window.location.hash=this.anchor.dataset.target;
        }
    }
}

export default class AnchorWC extends HTMLElement {
    constructor() {
        super();
        this.handler;
        this.defineHandler();
        this.clickListener();
    }

    defineHandler() {
        if (this.dataset.handler) {
            switch (this.dataset.handler) {
                case 'overlay':
                    this.handler = new OverlayHandler(this);
                    break;
                case 'scrollTo':
                    this.handler = new ScrollToHandler(this);
                    break;
                case 'openFeed':
                    this.handler = new OpenFeedHandler(this);
                    break;
                case 'simpleLink':
                  this.handler = new SimpleLink(this);
                  break;
            }

        }
    }


    clickListener() {
        if (this.handler) {
            this.addEventListener('click', e => {
                this.handler.handleClick(e);
            })
        }
    }
}
