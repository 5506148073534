import * as THREE from 'three';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import openSimplexNoise from "open-simplex-noise";

const noise = openSimplexNoise.makeNoise4D(Date.now());
const clock = new THREE.Clock();

let vertexShaderSourceString = `
    #define NORMAL
    #if defined( FLAT_SHADED ) || defined( USE_BUMPMAP ) || defined( TANGENTSPACE_NORMALMAP )
        varying vec3 vViewPosition;
    #endif
    #include <common>
    #include <uv_pars_vertex>
    #include <displacementmap_pars_vertex>
    #include <normal_pars_vertex>
    #include <morphtarget_pars_vertex>
    #include <skinning_pars_vertex>
    #include <logdepthbuf_pars_vertex>
    #include <clipping_planes_pars_vertex>
    void main() {
        #include <uv_vertex>
        #include <beginnormal_vertex>
        #include <morphnormal_vertex>
        #include <skinbase_vertex>
        #include <skinnormal_vertex>
        #include <defaultnormal_vertex>
        #include <normal_vertex>
        #include <begin_vertex>
        #include <morphtarget_vertex>
        #include <skinning_vertex>
        #include <displacementmap_vertex>
        #include <project_vertex>
        #include <logdepthbuf_vertex>
        #include <clipping_planes_vertex>
        #if defined( FLAT_SHADED ) || defined( USE_BUMPMAP ) || defined( TANGENTSPACE_NORMALMAP )
            vViewPosition = - mvPosition.xyz;
        #endif
    }
`;

let fragmentShaderSourceString = `
    uniform float time;
    #define NORMAL
    #if defined( FLAT_SHADED ) || defined( USE_BUMPMAP ) || defined( TANGENTSPACE_NORMALMAP )
        varying vec3 vViewPosition;
    #endif
    #include <packing>
    #include <uv_pars_fragment>
    #include <normal_pars_fragment>
    #include <bumpmap_pars_fragment>
    #include <normalmap_pars_fragment>
    #include <logdepthbuf_pars_fragment>
    #include <clipping_planes_pars_fragment>
    void main() {
        #include <clipping_planes_fragment>
        #include <logdepthbuf_fragment>
        #include <normal_fragment_begin>
        #include <normal_fragment_maps>

        vec3 lightColor = vec3(1, 1, 1);
        vec3 darkBlue = vec3(0.008,0.243,0.4);
        vec3 middleBlue = vec3(0.008,0.29,0.478);
        vec3 darkColor = vec3(0,0,0);
        vec3 sukoaBlue = vec3(0.,0.882,1.);

        float waveX2 = sin(normalize(normal).x * 20.0 + time * 2.0) * 0.05;
        float waveY2 = cos(normalize(normal).y * 20.0 + time * 2.0) * 0.05;

        float waveX3 = sin(normalize(normal).x * 5.0 + time * 0.5) * 0.03;
        float waveY3 = cos(normalize(normal).y * 5.0 + time * 0.5) * 0.03;

        float wave = sin(normalize(normal).x * 10.0 + time) * 0.1 + cos(normalize(normal).y * 10.0 + time) * 0.05;

        vec3 color = mix(middleBlue, darkColor, normalize(normal).x + wave);
        vec3 interpolatedColor = mix(color, lightColor, normalize(normal).z + wave);

        gl_FragColor = vec4(interpolatedColor, 1.0);
        #ifdef OPAQUE
            gl_FragColor.a = 0.92;
        #endif
    }
`;

class WaterMorph {
    constructor(el, size, animationSpeed, rotationSpeed, smoothness) {
        this.el = el;
        this.wrapper = el.querySelector('.canvasWrapper');
        this.dragable  = el.querySelector('.dragable-area');
        this.size = size;
        this.animationSpeed = animationSpeed;
        this.rotationSpeed = rotationSpeed;
        this.smoothness = smoothness;
        this.animationLoopIsRunning = false;

        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera( 75, size / size, 0.1, 1000 );
        this.camera.position.set(1.5, -0.5, 3.8);
        this.renderer = new THREE.WebGLRenderer({antialias: true, alpha: true});
        this.renderer.setSize( size, size );
        this.controls = new OrbitControls(this.camera, this.dragable)
        this.sphereGeometry = new THREE.SphereGeometry(1.5, 100, 100);
        this.sphereMesh = new THREE.ShaderMaterial({
            uniforms: {
                colorA: {type: 'vec3', value: new THREE.Vector3(0.5, 0.5, 0.5)},
                time: 8

            },
            vertexShader: vertexShaderSourceString,
            fragmentShader: fragmentShaderSourceString,
        });
        this.sphere = new THREE.Mesh(this.sphereGeometry, this.sphereMesh);
        this.v3 = new THREE.Vector3();

        this.mouseDown = false
        this.currentX = 0
        this.currentY = 0
        this.startX = 0
        this.startY = 0
        this.boundingClientX = this.wrapper.getBoundingClientRect().left
        this.boundingClientY = this.wrapper.getBoundingClientRect().top
        this.diffX = 0
        this.diffY = 0
        this.currentScrollY = window.scrollY;
        this.currentClientY = 0
        this.currentClientX = 0
        this.time = 0
        this.moveInterval = 0
        this.startTime = Date.now();
        this.rotationSignPositive = true
        this.rotationSignPositiveY = true
    }

    init() {
        this.render()
        this.addEventListeners()
        this.startAutomaticMovement()
    }

    startAutomaticMovement() {
        this.startTime = Date.now();
        this.moveInterval = setInterval(() => this.automaticMovement(), 50);
    }

    automaticMovement() {
        // CREATE RANDOM NUMBER WHICH BEHAVES LIKE A WAVE
        let timeElapsed = Date.now() - this.startTime
        let amplitudeX = 5
        let frequencyX = (Math.random() * 2 ) * 0.01
        let phaseOffsetX = 0
        let amplitudeY = 5
        let frequencyY = (Math.random() * 2 - 1) * 0.01
        let phaseOffsetY = Math.PI / 2
        let offsetX = Math.ceil(amplitudeX * Math.sin(frequencyX * timeElapsed + phaseOffsetX))
        let offsetY = Math.ceil(amplitudeY * Math.sin(frequencyY * timeElapsed + phaseOffsetY))


        // MAKE SIGN (+ / -) RANDOM
        let randomNumber = (Math.random() * 2) - 1
        if (randomNumber > 0) {
            offsetX = Math.abs(offsetX) * -1
            offsetY = Math.abs(offsetY) * -1
        } else {
            offsetX = Math.abs(offsetX)
            offsetY = Math.abs(offsetY)
        }


        // CHANGE DIRECTION IF MORPH IS ON THE BORDER AND MOVES FURTHER OUTSIDE
        if (this.wrapper.getBoundingClientRect().left <= (0 - (window.innerHeight / 8)) && offsetX >= 0) {
            offsetX = offsetX * -1
        }
        if (this.wrapper.getBoundingClientRect().right >= (window.innerWidth + (window.innerHeight / 8)) && offsetX <= 0) {
            offsetX = offsetX * -1
        }

        if (this.wrapper.getBoundingClientRect().top <= (0 - (window.innerHeight / 8) - window.scrollY) && offsetY >= 0) {
            offsetY = offsetY * -1
        }

        if (this.wrapper.getBoundingClientRect().bottom >= (window.innerHeight + (window.innerHeight / 8) - window.scrollY) && offsetY <= 0) {
            offsetY = offsetY * -1
        }

        // UPDATE CURRENT POSITION AND LAST BOUNDING CLIENT POSITION
        this.currentX = this.currentX + offsetX;
        this.currentY = this.currentY + offsetY;
        this.boundingClientX = this.boundingClientX - offsetX
        this.boundingClientY = this.boundingClientY - offsetY
        this.wrapper.style.transform = `translate(${-this.currentX}px, ${-this.currentY}px)`;
    }

    render() {
        this.wrapper.appendChild(this.renderer.domElement);
        this.controls.rotateSpeed = this.rotationSpeed; // Speed of camera rotation
        this.controls.zoomSpeed = 0;   // Speed of camera zooming

        // DISABLE RIGHT MOUSE BUTTON
        this.controls.mouseButtons = {
            LEFT: THREE.MOUSE.ROTATE,
            RIGHT: null,
        };

        this.calculateRotationSign()

        setTimeout(() => {

        }, 3000)

        this.sphereGeometry.positionData = [];
        /*let v3 = new THREE.Vector3();
        for (let i = 0; i < this.sphereGeometry.attributes.position.count; i++){
            v3.fromBufferAttribute(this.sphereGeometry.attributes.position, i);
            this.sphereGeometry.positionData.push(v3.clone());
        }*/
        this.scene.add(this.sphere);
        for (let i = 0; i < this.sphereGeometry.attributes.position.count; i++){
            this.v3.fromBufferAttribute(this.sphereGeometry.attributes.position, i);
            this.sphereGeometry.positionData.push(this.v3.clone());
        }
        if(window.scrollY <= window.innerHeight) {
            this.setAnimationLoop(true)
            this.animationLoopIsRunning = true
        }
    }

    setAnimationLoop() {
        const targetInterval = 10; // Time interval between frames in milliseconds

        let lastTime = 0; // Store the last time we updated the frame

        this.renderer.setAnimationLoop( (timestamp) => {
            const deltaTime = timestamp - lastTime;

            // If enough time has passed for the next frame, update
            if (deltaTime >= targetInterval) {
                lastTime = timestamp
                this.time = clock.getElapsedTime();
                let t = this.time
                this.sphereGeometry.positionData.forEach((p, idx) => {
                    let setNoise = noise(p.x, p.y, p.z, t * this.animationSpeed); // Speed
                    this.v3.copy(p).addScaledVector(p, setNoise / this.smoothness); // Noise
                    this.sphereGeometry.attributes.position.setXYZ(idx, this.v3.x, this.v3.y, this.v3.z);
                })
                this.sphereGeometry.computeVertexNormals();
                this.sphereGeometry.attributes.position.needsUpdate = true;


                if (this.rotationSign && this.rotationSignY) {
                    this.sphere.rotation.x += (Math.random() * -0.002); // Adjust rotation speed on x-axis
                    this.sphere.rotation.y += (Math.random() * 0.002); // Adjust rotation speed on y-axis
                } else if(this.rotationSign && !this.rotationSignY) {
                    this.sphere.rotation.x += (Math.random() * 0.002); // Adjust rotation speed on x-axis
                    this.sphere.rotation.y += (Math.random() * -0.002); // Adjust rotation speed on y-axis
                } else if(!this.rotationSign && this.rotationSignY) {
                    this.sphere.rotation.x += (Math.random() * 0.002); // Adjust rotation speed on x-axis
                    this.sphere.rotation.y += (Math.random() * 0.002); // Adjust rotation speed on y-axis
                } else {
                    this.sphere.rotation.x += (Math.random() * -0.002); // Adjust rotation speed on x-axis
                    this.sphere.rotation.y += (Math.random() * -0.002); // Adjust rotation speed on y-axis
                }

                this.renderer.render(this.scene, this.camera);
            }

        })
    }

    calculateRotationSign() {
        this.rotationSign = !this.rotationSign
        setTimeout(() => {
            this.calculateRotationSign()
        }, 6000)
    }

    calculateRotationSignY() {
        this.rotationSignY = !this.rotationSignY
        setTimeout(() => {
            this.calculateRotationSign()
        }, 6000)
    }

    addEventListeners() {
        this.dragable.addEventListener('mousedown', (e) => this.handleMouseDown(e))
        this.dragable.addEventListener('touchstart', (e) => this.handleTouchStart(e))

        const mousemove = (e) => this.handleMouseMove(e)
        window.addEventListener('mousemove', mousemove)

        const touchmove = (e) => this.handleTouchMove(e)
        window.addEventListener('touchmove', touchmove)

        const mouseup = (e) => this.handleMouseUp(e)
        window.addEventListener('mouseup', mouseup)

        const touchend = (e) => this.handleTouchEnd(e)
        window.addEventListener('touchend', touchend)

        const scroll = (e) => this.handleScrollEvent(e)
        document.addEventListener('scroll', scroll)
    }

    handleMouseDown(e) {
        if(e.button === 0) {
            clearInterval(this.moveInterval)
            this.mouseDown = true

            // UPDATE CURRENT POSITION IF TRANSITION IS NOT FINISHED
            let diffOldNewX = this.boundingClientX - this.wrapper.getBoundingClientRect().left
            let diffDiffX = this.diffX - diffOldNewX
            let diffOldNewY = this.boundingClientY - this.wrapper.getBoundingClientRect().top
            let diffDiffY = this.diffY - diffOldNewY
            if (diffDiffX !== 0 ) {
                this.currentX = this.currentX - diffDiffX
            }
            if (diffDiffY !== 0 ) {
                this.currentY = this.currentY - diffDiffY
            }

            // RESET DIFFERENCE FROM LAST MOVEMENT
            this.diffX = 0
            this.diffY = 0

            this.wrapper.classList.remove('transition-transform')
            this.wrapper.style.transform = `translate(${(-(this.currentX))}px, ${(-(this.currentY))}px)`
            this.startX = e.clientX
            this.startY = e.clientY
            this.boundingClientX = this.wrapper.getBoundingClientRect().left
            this.boundingClientY = this.wrapper.getBoundingClientRect().top
        }
    }

    handleTouchStart(e) {
        clearInterval(this.moveInterval)
        this.mouseDown = true

        // UPDATE CURRENT POSITION IF TRANSITION IS NOT FINISHED
        let diffOldNewX = this.boundingClientX - this.wrapper.getBoundingClientRect().left
        let diffDiffX = this.diffX - diffOldNewX
        let diffOldNewY = this.boundingClientY - this.wrapper.getBoundingClientRect().top
        let diffDiffY = this.diffY - diffOldNewY
        if (diffDiffX !== 0 ) {
            this.currentX = this.currentX - diffDiffX
        }
        if (diffDiffY !== 0 ) {
            this.currentY = this.currentY - diffDiffY
        }

        // RESET DIFFERENCE FROM LAST MOVEMENT
        this.diffX = 0
        this.diffY = 0

        this.wrapper.classList.remove('transition-transform')
        this.wrapper.style.transform = `translate(${(-(this.currentX))}px, ${(-(this.currentY))}px)`
        this.startX = e.touches[0].clientX
        this.startY = e.touches[0].clientY
        this.boundingClientX = this.wrapper.getBoundingClientRect().left
        this.boundingClientY = this.wrapper.getBoundingClientRect().top
    }

    handleMouseMove(e) {
        if (this.mouseDown) {
            let clientX = e.clientX;
            let clientY = e.clientY;


            // ONLY UPDATE DIFFERENCE IF IT STAYS INSIDE
            if (this.wrapper.getBoundingClientRect().left > (0 - (window.innerWidth / 8)) || clientX > this.currentClientX) {
                if (this.wrapper.getBoundingClientRect().right < (window.innerWidth + (window.innerWidth / 8)) || clientX < this.currentClientX) {
                    this.diffX = this.startX - clientX
                }
            }
            if (this.wrapper.getBoundingClientRect().top > (0 - (window.innerHeight / 8) - window.scrollY) || clientY > this.currentClientY) {
                if (this.wrapper.getBoundingClientRect().bottom < (window.innerHeight + (window.innerHeight / 8) - window.scrollY) || clientY < this.currentClientY)
                this.diffY = this.startY - clientY
            }

            this.wrapper.style.transform = `translate(${(-(this.currentX + (this.diffX / 1.5)))}px, ${(-(this.currentY + (this.diffY / 1.5)))}px)`
            this.currentClientY = clientY
            this.currentClientX = clientX
        }
    }

    handleTouchMove(e) {
        if (this.mouseDown) {
            let clientX = e.touches[0].clientX;
            let clientY = e.touches[0].clientY;

            // ONLY UPDATE DIFFERENCE IF IT STAYS INSIDE
            if (this.wrapper.getBoundingClientRect().left > (0 - (window.innerWidth / 8)) || clientX > this.currentClientX) {
                if (this.wrapper.getBoundingClientRect().right < (window.innerWidth + (window.innerWidth / 8)) || clientX < this.currentClientX) {
                    this.diffX = this.startX - clientX
                }
            }
            if (this.wrapper.getBoundingClientRect().top > (0 - (window.innerHeight / 8) - window.scrollY) || clientY > this.currentClientY) {
                if (this.wrapper.getBoundingClientRect().bottom < (window.innerHeight + (window.innerHeight / 8) - window.scrollY) || clientY < this.currentClientY)
                    this.diffY = this.startY - clientY
            }

            this.wrapper.style.transform = `translate(${(-(this.currentX + (this.diffX / 1.5)))}px, ${(-(this.currentY + (this.diffY / 1.5)))}px)`
            this.currentClientY = clientY
            this.currentClientX = clientX
        }
    }

    handleMouseUp(e) {
        if (e.button === 0 && this.mouseDown) {
            this.mouseDown = false
            this.wrapper.classList.add('transition-transform');

            let differenceToEndPositionY = (this.currentY + this.diffY) - (this.currentY + (this.diffY / 1.5))
            let topEdge = -(window.innerHeight / 8)
            let bottomEdge = window.innerHeight + (window.innerHeight / 8)
            let differenceToEndPositionX = (this.currentX + this.diffX) - (this.currentX + (this.diffX / 1.5))
            let leftEdge = -(window.innerWidth / 8)
            let rightEdge = window.innerWidth  + (window.innerWidth / 8)

            // END POSITION STAYS INSIDE AND CREATES BOUNCE EFFECT IF IT GOES OUTSIDE
            if ((this.wrapper.getBoundingClientRect().top - differenceToEndPositionY > topEdge - window.scrollY) && (this.wrapper.getBoundingClientRect().bottom - differenceToEndPositionY < bottomEdge - window.scrollY)) {
                this.currentY = this.currentY + (this.diffY / 1)
            } else {
                this.currentY = this.currentY + (this.diffY / 4)
                this.diffY = this.diffY / 4
            }

            if ((this.wrapper.getBoundingClientRect().left - differenceToEndPositionX > leftEdge) && (this.wrapper.getBoundingClientRect().right - differenceToEndPositionX < rightEdge)) {
                this.currentX = this.currentX + (this.diffX / 1)
            } else {
                this.currentX = this.currentX + (this.diffX / 4)
                this.diffX = this.diffX / 4
            }

            this.wrapper.style.transform = `translate(${(-(this.currentX))}px, ${(-(this.currentY))}px)`
            this.startAutomaticMovement()
        }
    }

    handleTouchEnd(e) {
        if (this.mouseDown) {
            this.mouseDown = false
            this.wrapper.classList.add('transition-transform');
            let differenceToEndPositionY = (this.currentY + this.diffY) - (this.currentY + (this.diffY / 1.5))
            let topEdge = -(window.innerHeight / 8)
            let bottomEdge = window.innerHeight + (window.innerHeight / 8)
            let differenceToEndPositionX = (this.currentX + this.diffX) - (this.currentX + (this.diffX / 1.5))
            let leftEdge = -(window.innerWidth / 8)
            let rightEdge = window.innerWidth  + (window.innerWidth / 8)

            // END POSITION STAYS INSIDE AND CREATES BOUNCE EFFECT IF IT GOES OUTSIDE
            if ((this.wrapper.getBoundingClientRect().top - differenceToEndPositionY > topEdge - window.scrollY) && (this.wrapper.getBoundingClientRect().bottom - differenceToEndPositionY < bottomEdge - window.scrollY)) {
                this.currentY = this.currentY + (this.diffY / 1)
            } else {
                this.currentY = this.currentY + (this.diffY / 4)
                this.diffY = this.diffY / 4
            }

            if ((this.wrapper.getBoundingClientRect().left - differenceToEndPositionX > leftEdge) && (this.wrapper.getBoundingClientRect().right - differenceToEndPositionX < rightEdge)) {
                this.currentX = this.currentX + (this.diffX / 1)
            } else {
                this.currentX = this.currentX + (this.diffX / 4)
                this.diffX = this.diffX / 4
            }

            this.wrapper.style.transform = `translate(${(-(this.currentX))}px, ${(-(this.currentY))}px)`
            this.startAutomaticMovement()
        }
    }

    handleScrollEvent(e) {
        let windowScrollY = window.scrollY
        let scrollDifference = this.currentScrollY - windowScrollY
        this.currentScrollY = windowScrollY
        this.boundingClientY += scrollDifference

        //quick impl to spare cpu when WaterMorph no more visible.
        if(window.scrollY > window.innerHeight) {
            this.renderer.setAnimationLoop(null);
            this.animationLoopIsRunning = false
        } else {
            if (this.animationLoopIsRunning === false) {
                this.setAnimationLoop()
                this.animationLoopIsRunning = true
            }
        }
    }
}



function createSVGFilters() {
    const svgNS = "http://www.w3.org/2000/svg";
    const svg = document.createElementNS(svgNS, 'svg');
    svg.setAttribute('style', 'display: none');

    for (let i = 1; i <= 101; i++) {
        const filter = document.createElementNS(svgNS, 'filter');
        filter.setAttribute('id', `turbulence-${i}`);

        const feTurbulence = document.createElementNS(svgNS, 'feTurbulence');
        feTurbulence.setAttribute('type', 'fractalNoise');
        feTurbulence.setAttribute('baseFrequency', (0.005 + (i - 1) * 0.00005).toFixed(4));
        feTurbulence.setAttribute('numOctaves', '2');

        const feDisplacementMap = document.createElementNS(svgNS, 'feDisplacementMap');
        feDisplacementMap.setAttribute('xChannelSelector', 'R');
        feDisplacementMap.setAttribute('yChannelSelector', 'G');
        feDisplacementMap.setAttribute('in', 'SourceGraphic');
        feDisplacementMap.setAttribute('scale', (50 + (i - 1) * 1).toString());

        const feGaussianBlur = document.createElementNS(svgNS, 'feGaussianBlur');
        feGaussianBlur.setAttribute('stdDeviation', '2');

        filter.appendChild(feTurbulence);
        filter.appendChild(feDisplacementMap);
        filter.appendChild(feGaussianBlur);

        svg.appendChild(filter);
    }
    document.body.appendChild(svg);
}

export default function init() {
    setTimeout(() => {
        createSVGFilters();
        const waterMorphEl = document.getElementsByClassName('waterMorphObject');
        if(waterMorphEl) {
            // LOOP THROUGH ALL WATER MORPH OBJECTS AND CREATE THEM WITH RANDOM/RESPONSIVE POSITION AND SIZE
            for(let i = 0; i < waterMorphEl.length; i++) {
                let size = parseInt(waterMorphEl[i].dataset.size) + (Math.random() * 2 - 1) * 80;
                let scaleFactor = 0.7
                if (window.innerWidth > 400) {
                    scaleFactor = 0.73
                }
                if (window.innerWidth > 600) {
                    scaleFactor = 0.79
                }
                if (window.innerWidth > 800) {
                    scaleFactor = 0.83
                }
                if (window.innerWidth > 1000) {
                    scaleFactor = 0.86
                }
                if (window.innerWidth > 1200) {
                    scaleFactor = 0.9
                }
                if (window.innerWidth > 1400) {
                    scaleFactor = 1
                }
                if (window.innerWidth > 1600) {
                    scaleFactor = 1.1
                }
                if (window.innerWidth > 1800) {
                    scaleFactor = 1.2
                }
                if (window.innerWidth > 2000) {
                    scaleFactor = 1.3
                }
                if (window.innerWidth > 2300) {
                    scaleFactor = 1.4
                }
                if (window.innerWidth > 2800) {
                    scaleFactor = 1.5
                }
                size = size * scaleFactor * 0.8

                let posX = (((Math.random() * 2 - 1) * 15) + (parseInt(waterMorphEl[i].dataset.posX) /2))
                let posY = (((Math.random() * 2 - 1) * 15) + (parseInt(waterMorphEl[i].dataset.posY)/ 2))
                let animationSpeed = parseInt(waterMorphEl[i].dataset.animationSpeed) / 10;
                let rotationSpeed = parseInt(waterMorphEl[i].dataset.rotationSpeed) / 50;
                let smoothness = parseInt(waterMorphEl[i].dataset.smoothness);
                waterMorphEl[i].style.transform = `translate(${posX}vw,${posY}vh)`;
                waterMorphEl[i].style.width = size + 'px';
                waterMorphEl[i].style.height = size + 'px';
                const waterMorph = new WaterMorph(waterMorphEl[i], size, animationSpeed, rotationSpeed, smoothness);
                waterMorph.init()
            }

            // ADD SQUIGGLE EFFECT ONLY IN CHROME
            let isChrome = ((navigator.userAgent.toLowerCase().indexOf('chrome') > -1) &&(navigator.vendor.toLowerCase().indexOf("google") > -1));
            if(isChrome) {
                let blurElements = document.getElementsByClassName('blur')
                for (let i = 0; i < blurElements.length; i++) {
                    blurElements[i].classList.add('squiggle')
                }
            }

            let isSafari = navigator.vendor.match(/apple/i) &&
                !navigator.userAgent.match(/crios/i) &&
                !navigator.userAgent.match(/fxios/i) &&
                !navigator.userAgent.match(/Opera|OPT\//);

            if (isSafari) {
                let blurElements = document.getElementsByClassName('blur')
                for (let i = 0; i < blurElements.length; i++) {
                    blurElements[i].classList.add('blur-effect-safari')
                }
            } else {
                let blurElements = document.getElementsByClassName('blur')
                for (let i = 0; i < blurElements.length; i++) {
                    blurElements[i].classList.add('blur-effect')
                }
            }

            // ENABLE SCROLLING WHEN MOUSE IS OVER MORPH
            setTimeout(() => {
                document.querySelectorAll('.canvasWrapper').forEach(canvas => {
                    const handleWheel = () => {
                        canvas.style.pointerEvents = 'none';
                        canvas.removeEventListener('wheel', handleWheel);
                        setTimeout(() => {
                            canvas.style.pointerEvents = 'auto';
                            canvas.addEventListener('wheel', handleWheel);
                        }, 500);
                    };
                    canvas.addEventListener('wheel', handleWheel);
                })
            }, 500)

            // TRY TO PREVENT WHITE BACKGROUND OF CANVAS
            window.addEventListener('beforeunload', () => {
                document.querySelectorAll('.canvasWrapper').forEach(canvas => {
                    canvas.style.opacity = '0';
                    setTimeout(() => {
                    }, 1000)
                })
            });
        }
    },100)
}